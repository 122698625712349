.container-fluid{padding-right: 5%;padding-left: 5%;}
.mr-10{margin-right:10px;}
.mb-0{margin-bottom: 0;}
.pb-10{padding-bottom: 10px;}
body{background-color: #ececec;font-family: 'Open Sans', sans-serif;font-size: 13px;}
.fa{font-size: 18px;color: #999;}
img{max-width: 100%;}
ul{padding-left: 0;}
ul li{list-style-type: none;}
header input.form-control::placeholder {color: #ffffff!important;}
a{color: #0b488b;}
h3{font-size: 23px;}
h4{font-size: 18px;}
.btn,input.form-control::placeholder{font-size: 14px;}
html {scroll-behavior: smooth;}
.btn:focus{box-shadow: none!important;}
.hidden{display: none;}
.floatRight{float: right}
.floatLeft{float: left}
p{font-size: 13px;line-height: 23px;}
/*i{cursor: pointer;}*/
.disabled{cursor: default;}

/*header*/
.header-top{background-color: #002A61;padding: 15px 0px;}
.logo img{height: 50px;}
.searchbar {position: relative;}
.searchbar .form-control{border: none;background: #0B488B;color: #ffffff;height: 40px;border-radius: 7px; padding: 0 35px 0 10px;  }
.searchbar .form-control:focus {box-shadow: none;}
.login-btn-container .btn{padding: 10px 15px;min-width: 90px;color: #000000;font-weight: bold;border-radius: 8px;border:none;}
.login-btn-container .login-btn{background-color: #e6e6e6;}
.login-btn-container .login-btn:hover{background-color: #cccccc;}
.login-btn-container .sign-up-btn{background-color: #ffbf5b;margin-right: 0;}
.login-btn-container .sign-up-btn:hover{background-color: #ffb23a;}
.login-btn-container .btn-success:not(:disabled):not(.disabled):active{    color: #000000;background-color: #e6e6e6;border-color: #e6e6e6;}
.icon-search{ background-size: 25px;background-image: url(../images/search-icon.png);width: 40px;height: 40px;display: block;position: absolute;top: 0;right: 0;background-repeat: no-repeat;background-position: center;}
.icon-search .submit-icon{cursor: pointer;border: 0;outline: 0;background-color: transparent;}

/*menu*/
.menu,.navbar-dark,.navbar-inverse{background-color: #01193b;border:none;}
.navbar{margin: 0;min-height: 40px;padding: 0;}
.navbar-nav>li a,.navbar-dark .navbar-nav>li>a {color: #ffffff!important;font-weight: bold;}
.navbar-nav>li>a {padding-top: 10px;padding-bottom: 10px;}
.navbar-dark .navbar-nav>.active>a, .navbar-dark .navbar-nav>.active>a:focus, .navbar-dark .navbar-nav>.active>a:hover,.navbar-dark .navbar-nav>li>a:focus, .navbar-dark .navbar-nav>li>a:hover{background-color: #0b488b;}
.navbar-dark{border-color: transparent;}
.navbar-dark .navbar-toggle:focus, .navbar-dark .navbar-toggle:hover{background-color: transparent;}


/*product list*/
.main{padding: 30px 0;min-height: 435px;}
.product_list ul{display: grid;    display: grid;grid-gap: 0px 10px;list-style: none;margin: 0;grid-template-columns: repeat(5, 1fr);}
.product_list li{list-style-type: none; vertical-align: top;padding-top: 0;padding-bottom: 20px;width: auto;display: block;flex: 1 1 0px;}
.product_item{background-color: #ffffff;padding: 10px;border-radius: 5px; box-shadow: 0px 3px 3px #afafaf;}
.item_image{text-align: center;margin-bottom: 5px;min-height: 160px;display: flex;align-items: center;justify-content: center;}
.product_item img{max-width: 160px;max-height: 160px;}
.product_item .item_title { padding-bottom:10px;}
.product_item .item_title a{ color: #000000 !important;font-size: 13px !important;max-height: 54px;line-height: 18px;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden;height: 54px;}
.item_price{font-size: 18px;font-weight: bold;color: #0B488B;padding-bottom: 5px;}
.item_details_btn{    border: none;font-size: 12px;width: 100%;background: #c3c3c3;padding: 10px;font-weight: 600;box-shadow: none;}
.item_details_btn:hover,.item_details_btn:active:hover{  border:none;  background-color: #002a61;}
.voting_icons{padding-bottom: 15px;}
.product_list .voting_icons i,.deal_vote_icons i{cursor: pointer;}
/* .dislikes_icon{display: none;} */
 .dislikes_icon{margin-left: 5px;} 
.likes_count,.comment_count{  margin-left: 3px;  border-radius: 5px;padding: 1px 4px;background-color: #F9B95B;color: #000000;font-size: 12px;font-weight: bold;}
.comment_label{float: right;}
.voting_label{float: left;}
/* .likes_icon i:hover,.deal_vote_icons .fa-thumbs-up:hover{color: #BBDA90;}
.dislikes_icon i:hover,.deal_vote_icons .fa-thumbs-down:hover{color: #F1ACAD;} */
.voting_label span{line-height: 15px;}
/* .product_list .voting_label:hover .likes_count{display: none;} */
/* .voting_label:hover .dislikes_icon{display: inline-block;margin-left: 10px;} */
.popular_deals .fa-thumbs-up:hover{color: #999;}
.comment_count{background-color: #5da110;color: #ffffff;}
.redux-lazy-scroll{overflow: auto!important;}

/*ad banner*/
.ad_banner_wrap{background-color: #ffffff;padding: 15px; box-shadow: 0px 3px 3px #afafaf;margin-bottom: 20px;text-align: center;}

/*popular deals*/
.popular_deals{background-color: #ffffff; box-shadow: 0px 3px 3px #afafaf;margin-bottom: 20px;}
.deals_title{width:100%;padding: 20px;border-bottom: 1px solid #C3C3C3;font-weight: bold;text-transform: uppercase;font-size: 16px;}
.deals_title .fa-rss{float: right;color: #f9b95b;}
.deals_items ul li{border-bottom: 1px solid #C3C3C3;padding: 20px;}
.deals_item_title{padding-bottom: 10px;}
.deals_item_title a{color: #000000;}
.deals_likes_count{margin-right: 30px;}
.popular_deals .voting_icons{padding-bottom: 0;}

/*signup*/
.signup_wrap{width: 450px;margin: 0 auto;}
.signup_icon{margin:0 auto;padding: 10px;border-radius: 50%;background-color:#ffbf5b;width: 30px;height: 30px; text-align: center;position: relative;margin-bottom: 10px;}
.signup_icon:before{font-family: FontAwesome;content: "\f023";vertical-align: middle;top: 0;position: absolute;bottom: 0;left: 0;right: 0;font-size: 18px;padding: 4px;color: #ffffff;}
.signup_wrap h3{    margin-top: 0;text-align: center;color: #000000;margin-bottom: 15px;}
.form-control {border: 1px solid #ccc;height: 45px;box-shadow: none;font-size: 14px;}
.signin_note{display: block;text-align: right;}
.form-control::placeholder {color: #555!important;}
.form-control:focus{    box-shadow: 0 0 0 0.1rem rgba(0,123,255,.25);}
.signup_wrap .btn{
	/* background-color: #c3c3c3; */
	border: none;width: 100%;color: #ffffff;font-weight: 600;padding: 7px;}
.signup_wrap .btn:hover{background-color: #002a61;}
.checkbox-inline label,.signin_note,.forgot_pw{font-weight:600;}
.forgot_pw{float: left;}
.custom-checkbox .custom-control-label::before{border-radius: 0;}
.custom-control-input:checked~.custom-control-label::before {border-color: #002a61;background-color: #002a61;}
.signUp{padding-bottom: 15px;}
.disclaimerCopy{    font-size: 11px;text-align: center;margin-top: 30px;}
.signup_wrap .form-control{background:#ffffff; }
.privacy_content ul{padding-left: 30px;}
.privacy_content ul li{list-style: disc;}

/*user profile*/
.user_profile{text-align: right;}
.login-btn-container{float: right;}
.user_container,.login-btn-container{display: inline-block;}
.user img{width: 35px;height: 35px;}
.user_container ul{margin-bottom: 0;}
.user_name,.user{display: inline-block;}
.user_name  a.dropdown-toggle{color: #ffffff;position: relative;padding:12px;display: inline-block;font-size: 13px;font-weight: 600;}
.user_name  a.dropdown-toggle::after{vertical-align: middle;}
.user_name a:hover{text-decoration: none;}
.user_name{position: relative;text-align: center;vertical-align: middle;}
.user_profile .dropdown-menu{box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);border-radius: 4px;}
.user_profile .dropdown-menu li a{padding:6px 10px;display: block;font-size: 13px;color: #333;font-weight: 600;}
.user_profile .dropdown-menu li a:hover{background-color: #0b488b;color: #ffffff;}
.user_profile .dropdown-submenu li a {padding: 5px;border-top: none;padding-left: 20px;}
.user_profile li.dropdown{position: relative;}
.user_profile li.dropdown:after{position: absolute;right: 10px;top: 5px;content: '\f0d7';font-family: 'FontAwesome';color: #d6d6d6;}
.user_profile .dropdown-menu,.user_profile .dropdown-submenu{display: none;}



/*deal post*/
.dealPosterContainer{    margin: 0 auto;border-radius: 4px;}
.post_deal{background: #fff;padding:50px 40px;position: relative;border: 1px solid #d6d6d6;border-right: 1px solid #e9e9e9;border-radius: 4px 0 0 4px;}
.progress_sidebar {background: #f8f8f8;text-align: center;padding: 50px 40px;   overflow: hidden;text-align: center;border-top: 1px solid #d6d6d6;border-right: 1px solid #d6d6d6;border-bottom: 1px solid #d6d6d6;border-left: 0px;border-radius: 0 4px 4px 0;}
.postdeals_header{text-align: center;}
.note-popover .popover-content, .panel-heading.note-toolbar{background-color: #ececec;}
.post_deal form label{font-weight: 600;}
.note-editor.note-frame .note-editing-area .note-editable{color: #495057;min-height: 100px;}
.select2-container{width: 100%!important;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered{padding:5px;}
.select2-container--default.select2-container--focus .select2-selection--multiple,.select2-container--default .select2-selection--multiple{border: 1px solid #ccc;background: transparent;}
.post_deal form .form-control{height: 40px;}
.note-popover.bottom{display: none;}
.btn-primary{background-color: #002a61;border:none;  margin-right: 10px;}
.btn-primary:hover,.btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle{background-color: #0b488b;}
 .btn-primary:not(:disabled):not(.disabled):active{background-color: #0b488b;border-color:#0b488b; }
.btn-secondary{background-color: #c3c3c3;border:none;}
.btn-secondary:hover, .post_deal .btn-secondary.active, .btn-secondary:active, .show>.btn-secondary.dropdown-toggle{background-color: #aaaaaa;}
.deal_note p{font-size: 12px;}
.progress-bar{background-color: transparent;margin: 50px auto;margin-bottom: 20px;}
.progress-bar div span{    background-color: #f8f8f8;color: #c7c7c7;font-size: 40px;}


/*#statusIndicator {display: block;padding: 0;list-style: none;counter-reset: numList;font-size: 22px;text-align: left;}
#statusIndicator li {position: relative;color: #c7c7c7;border-bottom: 1px solid #e9e9e9;font-weight: 600;}
#statusIndicator li strong {margin: 15px 0 15px;padding: 0 0 0 43px;width: 95%;display: block;font-size: 18px;font-weight: 500;}
#statusIndicator li strong:after {content: "\f00c";font-family: 'FontAwesome';font-size: 16px;right: 13px;position: absolute;top: 3px;color: #d6d6d6;-webkit-font-smoothing: antialiased;}
#statusIndicator li i {float: right;}
#statusIndicator li.current {color: #0b488b;}
#statusIndicator li.current:before {color: #0b488b;border: 2px solid #0b488b;}
#statusIndicator li.current .statusPopup {display: block;}
#statusIndicator li.complete {color: #999;}
#statusIndicator li.complete:before {background: #72BC00;border: 2px solid #72BC00;color: #fff;}
#statusIndicator li.complete :after {color: #72BC00;}
#statusIndicator li:before {counter-increment: numList;content: counter(numList);float: left;position: absolute;left: 10px;font-size: 15px;text-align: center;color: #c7c7c7;line-height: 21px;width: 25px;height: 25px;border-radius: 999px;font-weight: 600;border: 2px solid #c7c7c7;-webkit-font-smoothing: antialiased;}
#statusIndicator li .statusPopup {background: #D0ECFF;color: #41647B;font-size: 13px;padding: 14px 13px;margin: 0 10px 18px;font-weight: normal;border-radius: 4px;display: none;}
#statusIndicator .icon.icon-checkmark {float: right;color: #ccc;margin-top: 3px;}*/

#statusIndicator {display: block;padding: 0;list-style: none;counter-reset: numList;font-size: 22px;text-align: left;margin-bottom: 50px}
#statusIndicator li {position: relative;color: #8e8e8d;font-weight: 600;}
#statusIndicator li strong {    margin: 15px 0 ;padding: 0 0 0 70px;width: 100%;display: block;font-size: 18px;font-weight: 500;line-height: 50px;position: relative;}
#statusIndicator li.complete:after {    border: 2px solid #f8f8f8;content: "\f00c";font-family: 'FontAwesome';font-size: 10px;left: 44px;position: absolute;top: -3px;width: 22px;height: 22px;background: #72bc00;text-align: center;color: #ffffff;font-weight: 100;border-radius: 50%;line-height: 18px;-webkit-font-smoothing: antialiased;}
#statusIndicator li i {float: right;}
#statusIndicator li.current {color: #ffbf5b;}
#statusIndicator li.current:before {    color: #ffbf5b;border: 2px solid #ffbf5b;background: no-repeat;}
#statusIndicator li.complete{color:#ffbf5b; }
#statusIndicator li.complete:before {    background: #ffbf5b;border: 2px solid #ffbf5b;color: #7f602f;}
#statusIndicator li.complete :after {color: #72BC00;}
#statusIndicator li:before {    content: counter(numList);font-family: 'FontAwesome';float: left;position: absolute;left: 10px;font-size: 15px;text-align: center;color: #8e8e8d;line-height: 46px;width: 50px;height: 50px;border-radius: 999px;font-weight: 100;border: 2px solid #dadad9;background: #dadad9;-webkit-font-smoothing: antialiased;}
#statusIndicator li .statusPopup {background: #f8f8f8;color: #8e8e96;font-size: 13px;padding: 10px 10px;margin: 0 10px 18px;font-weight: normal;border-radius: 4px;display: none;line-height: normal;border:1px solid #ccc;}
#statusIndicator li.current .statusPopup {display: block;}
#statusIndicator .icon.icon-checkmark {float: right;color: #ccc;margin-top: 3px;}
#statusIndicator li.url:before{content: '\f0c1'}
#statusIndicator li.title:before{content:'\f031';}
#statusIndicator li.price:before{content:'\f155'}
#statusIndicator li.description:before{content:'\f0f6'}
#statusIndicator li.category:before{content:'\f0ca'}
#statusIndicator li.store:before{content:'\f07a'}
#statusIndicator li.brands:before{content:'\f02c'}
#statusIndicator li.images:before{content:'\f03e'}

.postImgWrp {border: 1px solid #ffc107;text-align: center;margin-bottom: 10px;min-height: 150px;display: flex;align-items: center;justify-content: center;position: relative;border-radius: 4px;padding: 10px 0;}
.postImgWrp img{max-width: 100px;max-height: 100px;}
.primaryRadio{position: absolute;bottom: 0;font-size: 11px;left: 5px;}
.primaryRadio span{padding-top: .25rem;display: inline-block;}
.primaryRadio .custom-radio .custom-control-input:checked~.custom-control-label::after{background-image:none;}
.primaryRadio .custom-control-label::before{background-color: transparent;border: none;content: "\f005";font-family: FontAwesome;color: #ccc;font-size:12px;}
.primaryRadio .custom-control-input:checked~.custom-control-label:before, .primaryRadio .custom-control-input:not(:disabled):active~.custom-control-label::before {border-color: none!important;background-color: transparent!important;color: #002a61;}
.primaryRadio .custom-control-input:focus~.custom-control-label::before {box-shadow: none;} 
.primaryRadio .custom-control-input:checked~.custom-control-label span.mkPrimary{display:none;}
.primaryRadio span.primary_text,.primaryRadio .custom-control-label > span{display:none;}
.primaryRadio .custom-control-input:checked~.custom-control-label span.primary_text,.primaryRadio .custom-control:hover .custom-control-label > span.mkPrime{display :block;}
.dropzone{border:1px dashed #d6d6d6;padding:15px;margin-bottom:15px;}
.dropzone p{margin-bottom:0;}
span.postImg_DltWrp {position: relative;}
span.postImg_DltWrp i{  display:none;  color: #fff;vertical-align: middle;line-height: 30px;width: 30px;height: 30px;background: #d82323;border-radius: 50%;    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);position: absolute;top: 0;left: 0;right: 0;bottom: 0;margin: auto;}
span.postImg_DltWrp:hover img{opacity: .2;}
span.postImg_DltWrp:hover i{display: block;}
/*searchpage*/
.search_results_num{font-size: 16px;color: #212529;}
.searchTools{text-align: right;}
.searchTools a{font-size: 11px;padding-left:10px; color: #212529;}
.searchTools a span{display: inline-block;padding-right: 5px;}
.searchTools a span i{font-size: 11px;color: #999;}
.searchTools .deal_alert_text,.searchTools .deal_alert_text  i {color:red;}
hr.gridSeparator {background-color: #f8f9fa;width: 100%;height: 1px;border: 0;border-top: 1px #d6d6d6 solid;clear: both;}
.search_info .tab-content{background: #fff;border: 1px solid #dee2e6;border-top: 0;padding: 10px;}
.search_info .nav-tabs .nav-link{color:#212529;font-weight:600;    border: 1px solid #dee2e6;background-color: #ffffff;margin-right: 10px; font-size: 13px;}
.search_info .nav-tabs .nav-link.active {color: #0b488b;border-bottom: 1px solid #ffffff;background-color: #ffffff}
.notifBar {border-radius: 4px;background-color: #e6e6e6;font-size: 13px;color: #333;line-height: 20px;padding: 10px 15px;}
.search_results_table{margin:20px 0;}
.search_results_table th,.search_results_table td{font-size: 13px;vertical-align: middle;}
.deal_img img{width: 60px;max-width: 60px;max-height: 60px;}
.search_results_table thead th{    border-bottom: 1px solid #dee2e6;    padding: 10px;vertical-align: middle;background: #fcfcfc;font-weight: 600;}
.search_results_table table{margin-bottom: 0;border: 1px solid #dee2e6;}
.search_results_table tbody td{padding:10px;}
.search_results_table .mainDealInfo{max-width: 500px;}
.search_results_table .mainDealInfo a.dealTitle{font-size:14px;font-weight: 600;}
.search_results_table .mainDealInfo .dealInfo{font-size: 12px;padding-top: 5px;}
.search_results_table .mainDealInfo .dealInfo i{color: #ffbf5b;font-size: 13px;padding-right: 5px;}
.search_results_table .mainDealInfo .dealInfo .featuredSponsor{padding-right: 10px;}
.search_results_table .deal_price .price {color: #e29a2a;font-family: "Open Sans Semibold", "Open Sans", Arial, sans-serif;font-style: normal;font-weight: 600;font-size: 14px;}
.search_results_table .deal_price .store{font-size: 12px;}
.search_results_table .deal_price .fa-fire{color: #d82323;font-size: 14px;}
.search_results_table .deal_rating .ratingNum {color: #0b488b;font-weight: 700;font-size: 14px;padding-bottom: 5px;}
.search_results_table  .fa-star{font-size: 14px;color: #ffd800;}
.search_results_table .deal_rating .rating{padding-bottom: 5px;display: block;}
.search_results_table .dealBadge{width: fit-content;padding: 0px 5px;border-radius: 3px;background: #0b488b;font-size: 12px;color: #fff;line-height: 20px}
.search_results_table .dealBadge.expired{background-color: #999;}
.search_results_table .dealBadge.popular {background-color: #ffb851;}
.search_results_table .deal_activity{font-size: 12px;}
.search_results_table .deal_activity i{padding-right: 5px;  font-size: 12px;color: #999;}
.deal_sort select{border: 1px solid #dee2e6;border-radius: 3px;    font-size: 12px;font-weight: 600;}
.deal_sort select:focus{outline: 0;}
.fa-arrow-circle-right{padding-left: 5px;font-size: 12px;color: #999;}
.search_results_table td{font-size: 12px;}
.dealAlertRow {text-align: center;border-bottom: 1px solid #e9e9e9;background-color: #e9e9e9;padding: 30px }
.dealAlertRow .title {display: inline-block;font-style: normal;font-weight: 600;color: #444;font-size: 16px;margin-right: 15px;padding: 10px 0}
.dealalert_btn{display: inline-block;}
.dealalerts_subscribe {background: #ffbf5b;color: #000;  font-weight: 700;font-size: 13px;padding: 10px;border-radius: 4px;}
.dealalerts_subscribe:hover{text-decoration: none;}
.deals_table_pagination{padding-top: 20px;}
.deals_table_pagination .pagination{margin-bottom: 0;}
.searchFooter{    background-color: #fff;border-radius: 4px;border: 1px solid #d6d6d6;margin: 10px 0 0;padding: 20px 30px;font-size: 13px;}
.searchFooter .title{font-size: 22px;padding-bottom: 20px;}
#searchresult_info{font-size: 13px;}
.search_results_num .fa-info-circle{font-size: 14px;color: #999;}
.search_results_num a:focus{outline: none;}
.resultRow.expired .dealTitle ,.resultRow.expired .ratingNum{color: #999}
.search_info{margin-bottom: 15px;}
.page-item.active .page-link{background: #0056b3;border-color: #0056b3;}


/*deal page*/
.deal_box{background-color: #fff;border-radius: 4px 4px 0 0;border: 1px solid #d6d6d6;padding: 10px 20px;}
.topline{    border-top: 6px solid #333;}
.frontpageDeal .topline{border-top-color: #ffffff;}
.deal_header_top .featuredDeal {color: #002a61;text-align: center;font-size: 14px;border-radius: 2px;padding: 0 5px;display: inline-block;line-height: 18px;margin-top: 7px;background-color: #333;	}
.frontpageDeal .deal_header_top .featuredDeal{ background-color: #abcbee;}
.storeLogo{height: 40px;max-width: 100%;width: auto;}
.deal_header_top{border-bottom: 1px solid #dee2e6;padding-bottom: 15px;}
#dealTitle h1{font-size: 30px;margin: 20px 0;font-weight: 600;}
.deal_new_price{    font-size: 40px;font-weight: 700;padding-right: 10px;display: inline-block;vertical-align: middle;    color: #e29a2a;}
.deal_old_price{    font-size: 18px;color: #d82323;text-decoration: line-through;vertical-align: middle;display: inline-block;padding-right: 5px;}
.deal_price_extra_details{font-size: 13px;    font-style: italic;}
.dealScore,.deal_view_num,.deal_comments_num{display: inline-block;padding-right: 10px;vertical-align: middle;}
.deal_score_section{padding: 10px 0;font-size: 13px;}
.dealScoreBox{    background-color: #999;color: #fff;border-radius: 2px;padding:1px 2px;margin-right: 3px;}
.deal_score_section i{font-size: 13px;color: #999;}
/* .deal_description{padding:15px;border:1px solid #dee2e6;margin-bottom: 40px;} */
.deal_description{padding: 10px;border-radius: 5px; box-shadow: 0px 3px 3px #afafaf;margin-bottom: 40px;}
.detailImages{margin-bottom: 15px;}
.detailImages .thumbs {margin-bottom: 0;}
.detailImages .carousel .thumbs-wrapper{margin: 0;    margin: 0;padding: 10px;background: #ffffff;margin-top: 15px;border: 1px solid #dee2e6;}
.deal_promo{font-size:0;padding:10px;padding-left:0;margin-bottom: 5px;border: 1px solid #F0F0F0;border-radius: 2px;background-color: #F9F9F9;box-shadow: 0 2px 4px 0 rgba(218,218,218,0.5);}
.deal_promo_img{display: inline-block;padding-right: 20px;width: 35%}
.promo_content{display: inline-block;vertical-align: middle;width: 65%}
.promo_content h4{    color: #e23173;font-weight: 600;}
.promo_content a{font-weight: 600;padding: 0 13px;width: 170px;height: 34px;margin-top: 18px;line-height: 34px;background: #e23173;border-radius: 3px;font-size: 12px;display: block;white-space: nowrap;text-align: center;color: #ffffff;}
.promo_content a:hover{text-decoration: none;background-color: #c72863;}
.see_deal_btn .btn{width:100%;max-width: 350px;padding: 10px;font-size: 18px;background-color: #002a61; border-radius: 3px;font-weight: 600;border: none;color: #ffffff;}
.see_deal_btn .btn:hover{background-color: #0b488b; }
.see_deal_btn{margin-bottom: 10px;border-bottom: 1px solid #e9e9e9;padding-bottom: 20px;text-align: center;}
.vote_share_container{padding: 0 30px;    padding-bottom: 10px;/*border-bottom: 1px solid #e9e9e9;*/margin-bottom: 10px;}
.deal_share_container{float: center;text-align: center;}
.deal_share_title,.deal_vote_title{font-size: 12px;padding-bottom: 5px;}
.deal_share_icons a{display: inline-block;padding-right: 2px;}
.deal_vote_container{float: right;text-align: center;}
.deal_likes_icon,.deal_dislikes_icon{    padding: 3px 6px;background-color: #eee;border-radius: 50%;margin-right: 5px;}
.deal_vote_icons i{font-size: 14px;}
.give_rep_points{padding-top: 10px;font-size: 12px;text-align: center;padding-bottom: 20px;}
.give_rep_points img {width: 20px;height: 20px;border: 1px solid #d6d6d6;margin-right: 3px;vertical-align: middle;}
.give_rep_block{padding-bottom: 5px;}
.give_rep_btn {border:none; background-color:transparent;}
.give_rep_btn i{font-size: 14px;color: #f9b95b;}
.deal_notes_title h6,.original_post_title h6 {font-size: 15px;font-weight: bold;margin-bottom: 0;}
.deal_notes_header_text,.original_post_header_text,.add_comment_header_text,.add_comment_note{font-size: 12px;}
.deal_notes_header_text span,.original_post_header_text span{font-size: 12px;}
.deal_notes_title,.original_post_title/*,.add_comment_title*/{    padding-bottom: 10px;border-bottom: 1px solid #e9e9e9;}
.original_post{margin-bottom: 20px;}
.original_post_title{cursor: pointer;}
.editors_note,.original_post_note{padding-top: 20px;padding-bottom: 40px;}
.original_post_title h6:after {font-family: 'FontAwesome';  content: "\f077";float: left; font-size: 12px;padding-right: 5px;color:#C3C3C3; }
.accordion .original_post_header.collapsed .original_post_title h6:after {content: "\f078"; }
.add_comment_note{padding-top: 10px;padding-bottom: 20px;}
.add-comment{background-color: #c3c3c3;color: #ffffff;}
.add-comment:hover{color: #ffffff;background:#002a61;}
.dot{padding-left: 5px;padding-right: 5px;}
.add_comment_header_text{line-height: 35px;}
.post_coupon_container,.post_coupon_container i{color: #e23173;}
.post_coupon_container i{font-size: 14px;padding-right: 5px;}
.comment_disclaimer_container{padding: 10px 20px;background: #fcfcfc;border: 1px solid #d6d6d6;border-top: none;border-radius: 0 0 4px 4px;font-size: 12px;font-style: italic;}
.comment_disclaimer_container i{font-size: 13px;padding-right: 5px;}
.deal_section {margin-bottom: 15px;}
.deal_status,.comment_box{background-color: #fff;border-radius: 4px;border: 1px solid #d6d6d6;margin-bottom: 20px;padding:20px;}
.deal_status_score,.deal_vote_btn_group,.deal_views_comments {margin-right: 20px;    display: inline-block;vertical-align: middle;}
.deal_status_score .label , .deal_status_score .score{float: left;text-align: center;height: 47px;}
.deal_status_score .label{    background-color: #96CA4E;color: white;border-radius: 3px 0 0 3px;font-size: 11px;padding: 8px 15px 2px;font-weight: bold;}
.deal_status_score .score{background-color: #CCE5A9;border-radius: 0 3px 3px 0;font-size: 18px;padding: 10px 15px 0;color: #395413;font-weight: 600;}
.deal_vote_btn_group span {padding: 10px 15px;border: 1px solid #d6d6d6;background-color: #eee;float: left;height: 47px;}
.deal_vote_btn_group i{    font-size: 24px;color: #999;}
.deal_vote_btn_group .like_vote{border-radius: 4px 0 0 4px;border-right: none;}
.deal_vote_btn_group .dislike_vote{border-radius: 0 4px 4px 0;}
/* .deal_vote_btn_group .like_vote i:hover,.fa-thumbs-up:hover{color: #BBDA90;}
.deal_vote_btn_group .dislike_vote i:hover,.fa-thumbs-down:hover{color: #F1ACAD;} */
.deal_views_comments i{font-size: 14px;}
.deal_status_view_num{ line-height: 47px;margin-right: 10px;display: inline-block;}
.deal_status_comments_num{   display: inline-block;line-height: 47px;}
.deal_actions {float: right;}
.deal_stat_price{    display: inline-block;vertical-align:middle;line-height: 47px;font-size: 30px;font-weight: 600;padding-right: 10px;color: #e29a2a;}
.deal_stat_old_price{    line-height: 47px;display: inline-block;vertical-align:middle;color: #d82323;font-size: 18px;text-decoration: line-through;padding-right: 10px;}
.buy-now-action{display: inline-block;vertical-align:middle;}
.buy-now-action a   {padding: 10px 20px;font-size: 18px;background-color: #002a61; border-radius: 3px;font-weight: 600;border: none;color: #ffffff;}
.buy-now-action a:hover{background-color: #0b488b; color: #ffffff;}
.pagination{margin-bottom: 0;}
.comment_num{font-size: 23px;font-weight: 600;}
.comment_box{padding: 0;}
.box_header,.post_reply_container,.post_bottom_pagination{border-bottom: 1px solid #d6d6d6;padding: 20px;margin-bottom: 20px;}
.box_header {margin-bottom: 0;}
.post_reply_container,.post_bottom_pagination{margin-bottom: 0;}
.user_avatar{width:75px;height: 75px;display: inline-block; padding-right: 15px;   vertical-align: top;}
.user_avatar img{width: 65px;height: 65px;}
.user_avatar_details{display: inline-block;font-size: 13px;}
.user_avatar_details div{padding-bottom: 5px;}
.user_avatar_details i{font-size: 12px;padding-right: 5px;}
.user_avatar_details .post_usename a{font-weight: 600;}
.invertedBadge.pro {display: inline-block;background-color: #f6c500;color: #fff;border-radius: 2px;font-size: 10px;line-height: 17px;padding: 0 4px;font-style: normal;font-weight: 600;}
.post_avatar_container,.post_text{display: inline-block;vertical-align: top;}
.post_avatar_container,.post_text{width:100%;}
.post_text_top,.post_text_bottom{color: #999;padding-bottom: 15px;}
.post_text_content{padding-bottom: 15px;}
.postLikesDislikes{padding-left: 10px;}
.page_counter{padding:5px 0;}
.joinTheConversationBox{padding: 20px;}
.joinTheConversation,.join_btn{display: inline-block;vertical-align: middle;}
.joinTheConversation{padding-right: 50px;line-height: 40px;}
.join_btn a{background-color: #c3c3c3;color: #fff;border-radius: 4px;padding: 10px 15px;font-size: 14px;}
.join_btn a:hover{background-color: #002a61;color: #fff;text-decoration: none;}
.image-checkbox{cursor: pointer;box-sizing: border-box;-moz-box-sizing: border-box;-webkit-box-sizing: border-box;border: 1px solid transparent;padding: 10px;outline: 0;    text-align: center;margin-bottom: 10px;min-height: 150px;display: flex;align-items: center;justify-content: center;position: relative;border-radius: 4px;padding: 10px 0;}
.image-checkbox input[type="checkbox"]{display: none;}
.image-checkbox-checked{border-color: #ffc107;}
.image-radio input[type="radio"] {display: none;}
.image-radio{display: none;    position: absolute;left:  15px;bottom: 0px;background: transparent;padding: 5px;}
.image-checkbox-checked + .image-radio{display: block;}
.image-radio i {color: #ccc;font-size: 13px;margin-right: 10px;}
.image-checkbox-checked + .image-radio-checked{display: block;}
.image-radio-checked i {display:inline-block !important;color: #002a61;}
.image-checkbox img{max-width: 100px;max-height: 100px;}
.image-radio span.primary_text{font-size: 11px;display: none;}
.image-radio-checked span.primary_text{display: inline-block;}
.css-1okebmr-indicatorSeparator{margin-top: 0!important;margin-bottom: 0!important;}
.css-1hb7zxy-IndicatorsContainer{background-color: #f8f8f8!important;border-radius: 0 4px 4px 0!important;}

/*profile */
.profile_view{padding-top: 0;}
.profile_header{background-color: #f4f4f4;border-bottom: 1px solid #d6d6d6;margin-bottom: 70px;border-top:  1px #d6d6d6 solid;}
.usernameArea{padding: 16px 0;}
.usernameArea img{width: 60px;height: 60px;    border-radius: 4px;border: 1px solid #d6d6d6;}
.userAvatar{border-radius: 4px;}
.profileUsername{font-size: 18px;display: inline-block;font-weight: 600;padding-left: 10px;}
.profileNav{margin-bottom: 0;}
.profileNav li a{    color: #666;text-decoration: none;padding: 30px 0px;display: block;}
.profileNav li{display: inline-block;font-size: 15px;font-weight: 600;border-bottom: 3px solid transparent;margin-right: 50px;}
.profileNav li:last-child{margin-right: 0;}
.profileNav li a span{padding-right: 10px;}
.profileNav li.active{border-color: #0b488b}
.profileNav li.active a,.profileNav li.active a i,.profileNav li a:hover, .profileNav li a:hover i{color: #0b488b;}
.profile_right{padding-left: 40px;border-left: 1px solid #d6d6d6; }
.profileSidebar .nav-tabs {margin-right: 30px;border: none; }
.profileSidebar .nav-tabs .nav-link{font-size: 15px;padding: 15px 0;color:#666;position: relative;border: none;cursor: pointer; }
.profileSidebar .nav-tabs .nav-link.active,.profileSidebar .nav-tabs .show>.nav-link,.profileSidebar .nav-tabs .nav-link:hover,.profileSidebar .nav-tabs .show>.nav-link:hover{background-color: transparent;color: #0b488b;}
.profileSidebar .nav-tabs .nav-item{border-bottom: 1px #d6d6d6 solid;border-radius: 0;}
.profileSidebar .nav-tabs .nav-link.active:after{position: absolute;top: 0;right: 0;content: '\f054';font-family: 'FontAwesome';    padding: 18px 0;font-size: 11px;}
.profileSidebar .nav-tabs li:last-child{border-bottom: none;}
.profileContent_headline h2{margin-bottom: 35px;}
.profileContent h4{font-weight: 700;margin-bottom: 20px;}
.aboutMe .item span i{font-size: 14px;}
.aboutMe .item span{margin-right: 10px;}
.aboutMe .item {    padding: 10px 0;border-bottom: 1px solid #d6d6d6;}
.aboutMe .item:last-child{border-bottom: none;}
.myStats .item{display: inline-block;text-align: center;    margin-bottom: 10px;min-width: 115px;}
.myStats .num{border-right: 1px solid #d6d6d6;    font-size: 24px;margin-bottom: 10px;min-width: 120px;}
.myStats .item:last-child .num{border-right:none;}
.myStats .num a{text-align:center;color: #212529;width: 40px;height: 40px;border-radius: 50%;background: #ffbd58;font-size: 15px;font-weight: 700;line-height: 40px;display: inline-block;}
.myStats .statLine a{font-size: 11px;color: #212529;}
.myStats .statLine i{font-size: 12px; color: #666;margin-right: 5px;}
.activityStream{margin-top: 30px;}
.activityStream .nav-tabs {border-bottom: 1px solid #d6d6d6;margin-bottom: 30px;}
.activityStream .nav-tabs .nav-link {border: 1px solid #d6d6d6;border-top-left-radius: 0;border-top-right-radius: 0;background: #f4f4f4;border-left: 0;color: #212529;padding: 10px 15px;}
.activityStream .nav-tabs .nav-item:first-child{    border-left: 1px solid #d6d6d6;}
.activityStream .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {color: #212529;background-color: transparent;border-color: #d6d6d6 #d6d6d6 #ececec;font-weight: 600;}
.activityStream nav{position: relative;}
.activityStream .activity_settings_icon{position: absolute;right: 0;padding: 10px 0;}
.activityStream .activity_settings_icon i:hover {color:#0b488b; }
.activityItem{border-bottom: 1px solid #d6d6d6;padding: 15px 10px;overflow: hidden;}
.activityItem .icon{font-size: 16px;color: #fff;float: left;width: 30px;height: 30px;text-align: center;border-radius: 50%;padding-top: 7px;margin-right: 15px;}
.activityItem .icon .fa{color: #ffffff;font-size: 14px;}
.activityItem .icon.icon-thumbs-up3 {background-color: #b1db78;}
.activityItem .icon.icon-thumbs-down {background-color: #f3aaaa;}
.activityItem .icon.icon-star3 {background-color: #f8c200;}
.activityItem .icon.icon-pencil2 {background-color: #333;}
.activityItem .icon.icon-bubble2 {background-color: #0072bc;}
.activityItem .activityInfo {float: left;position: relative;}
.privateBadge {background-color: #f4f4f4;color: #666;display: inline-block;padding: 3px 8px;font-size: 9px;border-radius: 2px;position: absolute;margin-left: 5px;}
.activityItem .author {font-style: italic;font-size: 12px;color: #999;}
.activityItem .date {float: right;}
.activityItem:last-child{border-bottom: none;}
.contribution_list_header{border-bottom: 1px solid #d6d6d6;padding-bottom: 15px;}
.contribution_list_header span{display: inline-block;padding-left: 5px;}
.contribution_list_header .btn{font-size: 13px;background: #c3c3c3;color: #fff;}
.contribution_list_header ul li a{    display: block;padding: 5px 10px;font-size: 13px;color: #212529;}
.contribution_list_header ul li a:hover{background: #0b488b;color: #fff;text-decoration: none;}
.contributionsList li {padding: 15px;color: #999;font-size: 12px;border-bottom: 1px solid #d6d6d6;}
.contributionsList .title {display: inline-block;font-weight: 600;font-size: 16px;}
.contributionsList .dateline {color: #999;font-style: italic;margin: 3px 0 5px 0;}
.contributionsList .bottom {font-style: normal;font-weight: 600;}
.contributionsList .bottom .icon .fa-thumbs-up{color: #ffd800;}
.contributionsList .bottom .icon .fa-thumbs-down{color: #e55656;}
.contributionsList #accordion{margin-top: 10px;}
.contributionsList .card{border:none;}
.contributionsList .card-header{    background-color: rgba(0,0,0,0.04);padding: 0 30px;border-bottom: 0;}
.contributionsList .card-link{    display: block;padding: 5px 0px;color: #999;border-bottom: 1px solid rgb(214, 214, 214);position: relative;}
.contributionsList .card-link.collapsed{border-bottom: none;}
.contributionsList .card-link:hover{color: #999;}
.contributionsList .card-body{    background-color: rgba(0, 0, 0, 0.04);color: #666;padding: 15px 30px;}
.contributionsList .card-link:after{    font-family: 'FontAwesome';content: "\f078";float: left;font-size: 12px;padding-right: 5px;color: #999; top:5px;   position: absolute;left: -20px;}
.contributionsList .card-link.collapsed:after{    content:"\f077";}
.contributionsList li.expired .title {color: #999;}
.expiredBadge {background-color: #f4f4f4;color: #333;font-size: 9px;text-transform: uppercase;border-radius: 2px;padding: 2px 8px;font-weight: 400;}
.filters{padding-bottom: 10px;border-bottom: 1px solid #d6d6d6;}
.filters li{display: inline-block;margin-right: 5px;}
.filters li a {color: #666;padding: 6px 15px;height: 30px;display: block;border-radius: 15px;background-color: #f8f8f8;text-decoration: none;font-size: 12px;margin-right: 2px;}
.filters li.active a, .filters li:hover a {background-color: #0072bc;color: #fff;}
.myVoting .date {margin-right: 5px;}
.myVoting .text {font-style: italic;}
.myVoting .num {font-weight: 600;}
.myVoting .share {color: #333;}
.myVoting .icon .fa-thumbs-up{color: #ffd800;}
.myVoting .icon.up i{color: #b5d786;}
.checkboxContainer input{    position: absolute;top: 7px;right: 30px;z-index: 111;}
.checkboxContainer .btn{padding: 10px 15px 10px 30px;}
.topLinks{margin-bottom: 15px;}
.inboxMessages .message {padding: 10px;overflow: hidden;border-bottom: 1px solid #d6d6d6;}
.inboxMessages .message a {color: #333;text-decoration: none;display: inline-block;font-weight: 600}
.inboxMessages .message input[type="checkbox"] {float: right;margin-top: 9px;}
.inboxMessages .message img {float: left;width: 30px;height: 30px;margin-right: 15px;border-radius: 50%;}
.inboxMessages .message .messageSender {float: left;width: 150px;max-width: 150px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-right: 20px;padding: 5px 0;}
.inboxMessages .message .messageSubject {float: left;width: 400px;max-width: 400px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-right: 35px;padding: 5px 0;}
.inboxMessages .message .timestamp {float: left;width: 80px;text-align: right;padding: 5px 0;}
.inboxMessages .message a:hover,.inboxMessages .message a.active {color: #0072bc;font-weight: 600;}
.account_basic_settings label{font-weight: 600;font-size: 13px;}
.btn.disabled, .btn:disabled{cursor: default;}
.account_basic_settings .textinput,.account_basic_settings select {font-style: normal;font-weight: 400;color: #333;border-radius: 3px;border: 1px solid #ccc;line-height: 17px;padding: 8px 11px;font-size: 13px;}
.account_basic_settings input[type="text"] ,.account_basic_settings input[type="email"],.account_basic_settings input[type="password"],.account_basic_settings select{margin-bottom: 5px;width: 100%;height: 36px;padding-right: 11px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.account_basic_settings textarea{width: 100%;}
.search-label input{height: 36px;min-width: 300px;margin-top: 20px;}
.account_basic_settings .btn_link{padding: 10px 0;font-size: 13px;font-weight: 600;color: #0b488b;}
.account_basic_settings .btn.small{background: #c3c3c3;color: #333;font-size: 13px;margin-top: 5px;font-weight: 600;}
.changeEmailContent .noPassword {font-size: 11px;display: block;margin-bottom: 10px;}
.pb10 {padding-bottom: 15px}
.mb10{margin-bottom: 15px;}
.account_basic_settings .pb10{border-bottom: 1px solid #d6d6d6}
.settingLink{font-weight: 600;}
.account_basic_settings .save_btn.small{    background: #0b488b;color: #fff;}
.profileContent .table-bordered.table-responsive {    background: #e4e4e4;border: #b7b7b7 solid 1px;display: table;}
.profileContent .table-bordered th{    background: #cecdcd;border: #c1c1c1 solid 1px;}
.profileContent .table-bordered td{    border: #dcd5d5 solid 1px;}
.profileContent .react-bootstrap-table-page-btns-ul{float: right;}
.profileContent .react-bootstrap-table-page-btns-ul .page-item.active .page-link {background-color: #0b488b;border-color: #002a61;}

/*privacy*/
.wrpBox{    background-color: #fff;border-radius: 4px;border: 1px solid #d6d6d6;margin: 10px 0 0;padding: 30px;font-size: 13px;}
.privacy_content h2{margin-bottom: 20px;text-align: center;}
.privacy_content .effective_date{text-align: center;}
.privacy_content ul li{line-height: 23px;}

/*deal alert*/
.deal_alert_container h2{font-weight: 600;margin-bottom: 20px;}
.searchDealAlerts{margin-bottom: 20px;}
.searchDealAlerts h4{font-weight: 700;margin-bottom: 15px}
/* .searchDealAlerts input{ border: 1px solid #ccc;background: transparent;height: 40px;box-shadow: none;font-size: 14px;border-radius: 4px;padding: 10px;width: 100%;} */
.moreOpt_container{padding: 15px 0;padding-bottom: 0;}
.moreOpt_container > a{font-weight: 600;}
.opt_link{position: relative;}
.opt_link:after{position: absolute;right: -20px;content:'\f077';font-family: 'FontAwesome';font-size: 12px;color: #0b488b;}
.opt_link.collapsed:after{content:'\f078';}
.opt_link.collapsed .fewOpt_text{display: none;}
.opt_link .moreOpt_text{display: none;}
.opt_link.collapsed .moreOpt_text{display: inline-block;}
.opt_link .fewOpt_text{display:inline-block;}
.moreOpt_container .titleInput{padding-bottom: 15px;}
.add_alert{background-color: #c84d0d;color: #ffffff;min-width: 250px;margin-top: 20px;}
.add_alert:hover{background-color: #d9540e; color : #ffffff	}
#moreOpt{padding: 15px 0;padding-bottom: 0;}
.guide{width: 250px;float: right;padding: 25px;background-color: #f4f4f4;border-radius: 4px;}
.guide .book_icon{margin-bottom: 15px;}
.searchDealAlerts .card-header{padding: 20px;border-bottom: none;border-top: 1px solid #d6d6d6;cursor: pointer;}
.searchDealAlerts .card-header:first-child{border-top: none;}
.searchDealAlerts .card-body{background-color: #f7f7f7;padding-top: 0;}
.searchDealAlerts .card-title{font-size: 14px;font-weight: 600;color: #212529;}
.searchDealAlerts .accordion .card-title:after {font-family: 'FontAwesome';  content: '\f077';float: left;margin-right: 10px; color: #999;font-weight: 400;}
.searchDealAlerts .accordion .card-header.collapsed .card-title:after {   content: "\f078"; }
.dealAlertControls{text-align: right;}
.inline{display: inline-block;}
.dealAlertControls .button{   padding-right: 10px;color: #000;font-weight: 600;border: 1px solid #d6d6d6;padding: 5px 10px;border-radius: 4px;margin-right: 5px;display: inline-block;background: #fff;}
.buttongroup{margin-right: 10px;}
.dealAlertControls .button i{font-size: 14px;margin-right: 5px;}
.dealAlertSettings{    padding: 20px;border: 1px solid #d6d6d6;background: #fff;border-radius: 4px;}
.dealAlertSettings > div{margin-bottom: 15px;}
.dealAlertSettings > div:last-child{margin-bottom: 0;}
.tagBox {display: inline-block;border-radius: 4px;text-align: center;background: #f2f2f2;color: #333;font-size: 13px;border: 1px solid #ccc;padding: 5px;margin-right: 5px;}
.catBox {display: inline-block;border-radius: 3px;text-align: center;background-color: #e8e8e8;color: #666;font-size: 11px;margin-right: 15px;padding: 2px 5px;width: auto;}
.btn-secondary{    background-color: #c3c3c3;}
.searchDealAlerts .modal-header{border-bottom: none;padding-bottom: 0}
.searchDealAlerts h2{margin-bottom: 5px;}
#shareByCopyBtn{position: absolute;right: 0;top: 0;margin-right: 0;bottom: 0;padding: 0 30px;border-radius: 0 4px 4px 0;}
.copyLink{position: relative;}
.deleteConfirmation span{margin-right: 10px;font-weight: 600;}
.showAllBtn{margin-top: 30px;}
.showAllBtn .btn{min-width: 250px;}
.avatarImg {width: 82px;height: 82px;background-color: #fff;border: 1px solid #d6d6d6;border-radius: 4px;margin-right: 10px;}
.alrtMsg {padding: 3px 10px;border: 1px solid transparent;border-radius: 4px;  width:auto;-webkit-animation: fadeinout 5s linear forwards;animation: fadeinout 5s linear forwards;opacity: 0;}
.success {color: #3c763d;background-color: #dff0d8;border-color: #d6e9c6;}
.failure {color: #a94442;background-color: #f2dede;border-color: #ebccd1;}
.warning {color: #8a6d3b;background-color: #fcf8e3;border-color: #faebcc;}
@-webkit-keyframes fadeinout {50% { opacity: 1; }}
@keyframes fadeinout {50% { opacity: 1; }}
.searchDealAlerts .btn-secondary{margin-top: 20px;margin-left: 10px;}
.spinner-border{position: fixed;left: 50%;top:50%;}


/*modal*/
.modal-header{border-bottom: none;}
.modal-footer{border-top: none;}
.modal-title{font-weight: 600;color: #002a61;}

/*carousel*/
.carousel .thumbs{text-align: center;}
.carousel .thumb{border:2px solid #fff!important;text-align: center;background: #ffffff;height: 80px;}
.carousel .thumb.selected, .carousel .thumb:hover{border:2px solid #999!important;}
.carousel .slide{background-color: transparent!important;}
.carousel .slide > div{background-color: transparent!important;}
.carousel .thumb img {max-height: 80px;  /* change this to whatever you want */width: auto!important;}
.carousel .slide img {max-height: 200px;  /* change this to whatever you want */width: auto!important;}


/*footer*/
footer{background-color: #222222;color: #ffffff}
.footer_top{padding: 40px 0;background-color: #222222;}
.footer_logo_name{font-size: 22px;font-weight: bold;color: #ffffff;text-transform: uppercase;display: inline-block;padding-left: 25px;}
footer h4{font-weight: bold;margin-top: 0;margin-bottom: 20px;}
.footer_links a{display: block;color: #ffffff;padding-bottom: 5px;}
footer i{color: #999!important;}
footer i:hover{color: #ffbd58!important;}
.social_icons a{    display: inline-block;padding-right: 10px;}
.footer_bottom{background-color: #181818;padding: 20px 0;color: #999!important;font-size: 13px;font-weight: 600;}
.footer_logo img{height:50px;}





/*Responsive styles*/
@media(max-width: 1380px){
	.product_list ul,.related_deals .product_list ul{grid-template-columns: repeat(4, 1fr);}
}
@media(max-width: 1024px){
	/*.container-fluid {padding-right: 4%;padding-left: 4%;}*/
	.logo img{height: auto!important;}
	.product_list ul,.related_deals .product_list ul{grid-template-columns: repeat(3, 1fr);}
	.vote_share_container,.deal_share_container{    padding: 0;padding-bottom: 10px;}
	.deal_share_container,.deal_vote_container{float: none;}
	.deal_actions{float: none;margin-top: 15px;}
	.deal_detailarea{padding-bottom: 15px;}
	.myStats .num{font-size: 20px;min-width: 100px;}
	.search_results_table .mainDealInfo{max-width: 400px;}
	.myStats .item{min-width: 105px;}
}
@media(max-width: 980px){

	.related_deals .product_list ul,.product_list ul{grid-template-columns: repeat(2, 1fr);}
	.profileNav li{margin-right: 30px;}
	.profileSidebar .nav-tabs{margin-right: 10px;}
	.profile_right{padding-left: 25px;}
	.profileContent_stat{display: block;}
	.profileContent_stat .col-md-4,.profileContent_stat .col-md-8{max-width: 100%}
	.aboutMe{margin-bottom: 15px;}
	.activityStream .nav-tabs .nav-link{font-size: 11px;}
	.activityItem .activityInfo{max-width: 75%}
	.inboxMessages .message a {width: 90%;}
	.inboxMessages .message .timestamp{text-align: left;}
	.guide{width: auto;}
	.dealAlertControls .button{    font-size: 12px;}
	.post_deal,.progress_sidebar{padding: 20px;}
	.search_results_table .mainDealInfo{max-width: 295px;}
	.myStats .item{min-width: 115px;}


}
@media(max-width: 767px){
	.product_list ul,.related_deals .product_list ul{grid-template-columns: repeat(2, 1fr);}
	.searchbar,.footer_logo,.footer_links{margin: 0;padding-bottom: 10px;}
	footer{text-align: center;}
	.signup_wrap{width: 425px;}
	.post_deal{border-right:1px solid #d6d6d6;border-radius: 4px;margin-bottom: 10px;}
	.progress_sidebar{border-left:1px solid #d6d6d6;border-radius: 4px;}
	.dealalert_btn,.dealAlertRow .title,.dealalerts_subscribe{display: block;}
	.search_results_num,.searchTools{text-align: center;}
	.logo,.search_results_num,.post_avatar_container{padding-bottom: 10px;}
	.profile_header{margin-bottom: 40px;}
	.profileSidebar{margin-bottom: 20px;}
	.profile_right{padding-left: 15px;border-left:none;}
	.guide{margin-top: 15px;}
	.search_results_num{font-size: 15px;}
	.search_results_table tbody td{padding:10px;border:none;display:block;}
	.search_results_table .deal_price,.search_results_table .deal_rating,.search_results_table .deal_activity,.search_results_table .deal_sort{display: inline-block;}
	.search_results_table thead{display: none;}
	.search_results_table .mainDealInfo{max-width: inherit!important;}
	.deal_img img {width: auto;max-width: 150px;max-height: inherit;}
	.search_results_table .deal_img{border-top: 1px solid #dee2e6;}
	.search_results_table tr:first-child .deal_img{border-top:none;}
	.progress_sidebar div div:first-child  {padding: 0 !important;}
    .progress_sidebar div div:last-child  { /* this is .sidebar */position: static !important;transform: none !important;width: auto !important;}

}

@media(max-width: 576px){
	.signup_wrap{width: 100%;}
	#firstName{margin-bottom: 15px;}
	.navbar-dark {background: transparent;position: absolute;top: 0;right: 0;left: 0;}
	.navbar-dark .navbar-toggler{    position: absolute;top: 25px;right: 5%;border: none;padding: 0;}
    .navbar-dark .navbar-collapse {background-color: #002a61;text-align: center;padding: 0;margin-top: 68px;z-index: 1;}
	.navbar-dark .navbar-toggler:focus{outline: none;}
	.navbar-dark .navbar-toggler-icon{background-image: none;position: relative;}
	.navbar-dark .navbar-toggler-icon:before{position: absolute;font-family: 'FontAwesome';top: 0;left: 10px;content: "\f0c9"; color: #e6e6e6;}
	.navbar-dark .navbar-toggler-icon:hover::before{color:#efbf5b; }
	.navbar-nav{margin: 0;}
	.logo {position: relative;width: 60px;z-index: 9999;}
	.navbar-collapse {transform: translateY(0px);transition:.4s ease;}
	.navbar-collapse.show {transform: translateY(0px);transition:.4s ease;}
	.search_results_table .deal_img{text-align: center;}
	.search_results_table tbody td{text-align: center;}
	.search_results_table .deal_price,.search_results_table .deal_rating{display: block;}
	.search_results_table .deal_activity,.search_results_table .deal_sort{display: none;}
	.search_results_table .dealBadge{margin: 0 auto;margin-bottom: 10px;}
	.search_results_table .deal_price .price,.search_results_table .mainDealInfo a.dealTitle{font-size: 16px;}
	.deal_notes_title h6, .original_post_title h6,.comment_num {margin-bottom: 10px;}
	.deal_notes_header_text, .original_post_header_text,  .add_comment_note{text-align: left!important;}
	.comment_num ,.comments_pagination {float: none;}
	.myStats .item{display: inline-block;margin-bottom: 10px;width: 50%}
	.myStats .num{margin-bottom: 0;border-right: none;}
	.activityStream .activity_settings_icon{top: -45px;}
	.nav-tabs .nav-item{    display: block;width: 100%;border-left: 1px solid #d6d6d6;margin-bottom: 0;}
	.activityStream .nav-tabs .nav-link{border-bottom: none;}
	.contribution_list_header .col-sm-6{text-align: center!important;margin-bottom: 10px;}
	.topLinks{text-align: center!important;}
	#messages .contribution_list_header .col-sm-6.text-right{width: auto;margin: auto;}
	.dealAlertControls{margin-top: 10px;text-align: center;}
	.buttongroup{text-align: center;}
	.dealAlertControls .button{margin: auto;font-size: 11px;   margin-bottom: 10px;}
	.buttongroup{margin-right: 0;}
	.profileSidebar .nav-tabs .nav-item{border-left: none;}





}

@media(max-width: 425px){
	.product_list ul,.related_deals .product_list ul{grid-template-columns: repeat(1, 1fr);}
	.searchDealAlerts .add_alert,.searchDealAlerts .btn-secondary{width: 100%;min-width: auto;margin-left: 0;}
	.profileContent .table-bordered.table-responsive{display: block;}
	.wrpBox{padding: 20px;}

}

@media(max-width: 375px){
	.profileNav li{display: block;margin-right: 0;}
	.profileNav li a{padding: 15px 0;}
	.buttongroup,.dealAlertControls .button {width: 100%;display: block;font-size: 14px;}
	#rc-imageselect, .g-recaptcha {transform:scale(0.90);-webkit-transform:scale(0.90);transform-origin:0 0;-webkit-transform-origin:0 0;}
	.signup_wrap .note a{display: block;float: none;text-align: center;padding-bottom: 10px;}
	.post_deal .btn{display: block;width: 100%;margin-bottom: 10px;}
	.carousel .thumb{width: 65px!important;height: 65px!important}
	.carousel .thumb img {max-height: 62px;}
	
	
}

#navbarSupportedContent .nav-item .active {

	background-color: #0b488b;
}
.filter-header .custom-control-input:focus{outline:none;}
.filter-header .custom-switch .custom-control-label::after{background-color:#01193b}
.filter-header .btn-group{display:flex!important;border-bottom:2px solid #01193b; }
.filter-header .btn-group .btn-secondary{border-radius:0!important;font-weight:600;border:none;font-size:13px;background-color:#ddd!important;color:#000!important;padding: 8px;}
.filter-header .btn-group .btn-secondary.active{background-color:#002a61!important;border-color:#002a61!important;color:#fff !important;}
.filter-header .custom-switch.custom-control .custom-control-label{font-weight:600;}
.filter-header{    z-index: 0;
	position: relative;}
	

	select:disabled {   background-color: rgba(239,239,239,0.3)!important;}
